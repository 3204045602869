"use client";

// import * as React from "react";
import Box from "@mui/material/Box";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import numeral from "numeral";
import moment from "moment";
// import { useSelector } from "react-redux"

import { LoadingButton } from "@mui/lab";

import * as yup from "yup";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuthStore } from "@/stores/authStore";
import { shallow } from "zustand/shallow";
import { useRouter } from "next/navigation";
import { useSnackbar } from "notistack";
import Image from "next/image";
import { blue, green, grey, purple, red } from "@mui/material/colors";
import http from "@/libs/http";
import { apiPath } from "@/configs/apiPath";

const postSpin = async (type: string) => {
  const { data } = await http.get(apiPath.wheel.wheelSpin(type));
  return data;
};

const Spinner = ({
  segments = [],
  segColors = [],
  winningSegment = {},
  onFinished = () => {},
  primaryColor = "black",
  contrastColor = "white",
  buttonText = "หมุน",
  isOnlyOnce = true,
  size = 290,
  upDuration = 100,
  downDuration = 1000,
  fontFamily = "proxima-nova",
}: {
  segments: any[];
  segColors?: string[];
  winningSegment?: any;
  onFinished?: any;
  primaryColor?: any;
  contrastColor?: any;
  buttonText?: any;
  isOnlyOnce?: any;
  size?: any;
  upDuration?: any;
  downDuration?: any;
  fontFamily?: any;
}) => {
  const theme = useTheme();
  const [user] = useAuthStore((state) => [state.user], shallow);
  const { enqueueSnackbar } = useSnackbar();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [isLoading, setLoading] = useState(false);
  const [winning, setWinning] = useState({});

  let currentSegment = "";
  let isStarted = false;
  const [isFinished, setFinished] = useState(false);
  let timerHandle: any = 0;
  const timerDelay = segments?.length;
  let angleCurrent = 0;
  let angleDelta = 0;
  let canvasContext: any;
  let maxSpeed = Math.PI / segments.length;
  const upTime = segments?.length * upDuration;
  const downTime = segments?.length * downDuration;
  let spinStart = 0;
  let frames = 0;

  const centerX = 155;
  const centerY = 155;

  useEffect(() => {
    if (canvasRef.current) {
      canvasContext = canvasRef.current.getContext("2d");
    }
    wheelInit();
    setTimeout(() => {
      window.scrollTo(0, 1);
    }, 0);
  }, []);

  const wheelInit = () => {
    initCanvas();
    wheelDraw();
  };

  const initCanvas = () => {
    let canvas = document.getElementById("canvas");
    if (navigator.appVersion.indexOf("MSIE") !== -1) {
      canvas = document.createElement("canvas");
      canvas.setAttribute("width", "305");
      canvas.setAttribute("height", "305");
      canvas.setAttribute("id", "canvas");
      const wheel = document.getElementById("wheel");
      if (wheel) {
        wheel.appendChild(canvas);
      }
    }
    if (canvas) {
      canvas.addEventListener(
        "click",
        async () => {
          await spin();
        },
        false
      );
      canvasContext = (canvas as HTMLCanvasElement).getContext("2d");
    }
  };

  const spin = async (type = "ticket") => {
    if (isLoading) {
      return false;
    }
    // const date = moment(user.wheel_date) // Wait to fix
    const date = moment().add(5, "seconds").format("YYYY-MM-DD HH:mm:ss");
    const datenow = moment().format("YYYY-MM-DD HH:mm:ss");
    const localStorageDateGet: any = localStorage.getItem("wheel_date");

    if (localStorageDateGet > datenow) {
      // toast({
      //   position: "top",
      //   containerStyle: {
      //     width: "400px",
      //     maxWidth: "100%",
      //     margin: "8px 8px 0 4px",
      //     padding: "0 8px",
      //   },
      //   render: () => (
      //     <Notification
      //       type={"error"}
      //       alert={t("app:การแจ้งเตือน")}
      //       message={t("app:ทำรายการไม่สำเร็จ")}
      //       description={t("app:รอ 5 วินาที ก่อนทำรายการใหม่อีกครั้ง")}
      //     />
      //   ),
      // });
      return false;
    } else {
      setLoading(true);
      localStorage.setItem("wheel_date", date);

      const res = await postSpin(type);
      const { status, msg, data } = res;
      const title = status ? "ทำรายการสำเร็จ" : "ทำรายการไม่สำเร็จ";

      if (status == false) {
        enqueueSnackbar(msg, {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        setLoading(false);
        return false;
      }

      let segments_find = segments;
      // segments_find = segments_find.filter(o => numeral(o.percent).value() > 0);

      const realWinning = segments_find.find(
        (o) => numeral(o.value).value() == numeral(data).value()
      );

      isStarted = true;
      if (timerHandle === 0) {
        spinStart = new Date().getTime();
        maxSpeed = Math.PI / segments?.length;
        frames = 0;
        timerHandle = setInterval(() => {
          onTimerTick(realWinning);
        }, timerDelay);
      }

      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
  };

  const onTimerTick = (win: any) => {
    frames++;
    draw();
    const duration = new Date().getTime() - spinStart;
    let progress = 0;
    let finished = false;
    if (duration < upTime) {
      progress = duration / upTime;
      angleDelta = maxSpeed * Math.sin((progress * Math.PI) / 2);
    } else {
      if (win) {
        if (currentSegment === win && frames > segments?.length) {
          progress = duration / upTime;
          angleDelta =
            maxSpeed * Math.sin((progress * Math.PI) / 2 + Math.PI / 2);
          progress = 1;
        } else {
          progress = duration / downTime;
          angleDelta =
            maxSpeed * Math.sin((progress * Math.PI) / 2 + Math.PI / 2);
        }
      } else {
        progress = duration / downTime;
        angleDelta =
          maxSpeed * Math.sin((progress * Math.PI) / 2 + Math.PI / 2);
      }
      if (progress >= 1) finished = true;
    }

    angleCurrent += angleDelta;
    while (angleCurrent >= Math.PI * 2) angleCurrent -= Math.PI * 2;
    if (finished) {
      setFinished(true);
      onFinished(currentSegment);
      clearInterval(timerHandle);
      timerHandle = 0;
      angleDelta = 0;
    }
  };

  const wheelDraw = () => {
    clear();
    drawWheel();
    drawNeedle();
  };

  const draw = () => {
    clear();
    drawWheel();
    drawNeedle();
  };

  const drawSegment = (key: any, lastAngle: any, angle: any) => {
    const ctx = canvasContext;
    const value = segments?.[key].text;
    ctx.save();
    ctx.beginPath();
    ctx.moveTo(centerX, centerY);
    ctx.arc(centerX, centerY, size, lastAngle, angle, false);
    ctx.lineTo(centerX, centerY);
    ctx.closePath();
    ctx.fillStyle =
      key % 2 === 0
        ? theme.wheel.segment.fillStyle1
        : theme.wheel.segment.fillStyle2;
    ctx.fill();
    ctx.stroke();
    ctx.save();
    ctx.translate(centerX, centerY);
    ctx.rotate((lastAngle + angle) / 2);
    ctx.fillStyle = key % 2 === 0 ? "black" : "white";

    ctx.font = "bold 1em" + fontFamily;
    ctx.fillText(value.substr(0, 21), size / 2 + 20, 0);
    ctx.restore();

    // ctx.restore()
  };

  const drawWheel = () => {
    const ctx = canvasContext;
    let lastAngle = angleCurrent;
    const len = segments?.length;
    const PI2 = Math.PI * 2;
    ctx.lineWidth = 1;
    ctx.strokeStyle = "black";
    ctx.textBaseline = "middle";
    ctx.textAlign = "center";
    ctx.font = "16px " + fontFamily;
    for (let i = 1; i <= len; i++) {
      const angle = PI2 * (i / len) + angleCurrent;
      drawSegment(i - 1, lastAngle, angle);
      lastAngle = angle;
    }

    // Draw a center circle
    ctx.beginPath();
    ctx.arc(centerX, centerY, 50, 0, PI2, false);
    ctx.closePath();
    ctx.fillStyle = theme.palette.primary;
    ctx.lineWidth = 10;
    ctx.strokeStyle = theme.wheel.borderColor;
    ctx.fill();
    ctx.font = "bold 28px " + fontFamily;
    ctx.fillStyle = theme.wheel.segment.fillStyle1;
    ctx.textAlign = "center";
    ctx.fillText(buttonText || "กงล้อ", centerX, centerY + 3);
    ctx.fillStyle = theme.wheel.borderColor;
    ctx.stroke();

    // Draw outer circle
    ctx.beginPath();
    ctx.arc(centerX, centerY, size, 0, PI2, false);
    ctx.closePath();

    ctx.lineWidth = 6;
    ctx.strokeStyle = theme.palette.primary;
    ctx.stroke();
  };

  const drawNeedle = () => {
    const ctx = canvasContext;
    ctx.lineWidth = 1;
    ctx.strokeStyle = contrastColor || "white";
    ctx.fileStyle = contrastColor || "white";
    ctx.beginPath();
    ctx.moveTo(centerX + 20, centerY - 50);
    ctx.lineTo(centerX - 20, centerY - 50);
    ctx.lineTo(centerX, centerY - 70);
    ctx.closePath();
    ctx.fill();
    const change = angleCurrent + Math.PI / 2;
    let i =
      segments?.length -
      Math.floor((change / (Math.PI * 2)) * segments?.length) -
      1;
    if (i < 0) i = i + segments?.length;
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    // ** เปลี่ยนสีตรงนี้
    ctx.fillStyle = theme.palette.secondary;
    ctx.font = "bold 0.8em " + fontFamily;
    currentSegment = segments?.[i];
  };
  const clear = () => {
    const ctx = canvasContext;
    ctx?.clearRect(0, 0, 305, 305);
  };
  return (
    <Box>
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"} sx={{ cursor: 'pointer' }}>
        <canvas
          id="canvas"
          width="315"
          height="315"
          style={{
            cursor: "disabled",
            pointerEvents: isFinished && isOnlyOnce ? "none" : "auto",
          }}
        />
      </Box>
      <Stack direction="row" alignItems="center" justifyContent="center">
        <Typography sx={{color: (theme) => theme.gradient[900] }}>{`ตั๋วฟรีคงเหลือ = ${user?.wheel_ticket ? 1 : 0}`}</Typography>
      </Stack>
      <Stack direction="column" alignItems="center" justifyContent="center">
        <Typography sx={{color: (theme) => theme.gradient[900] }}>หมุนได้ {user?.wheel_ticket ? 1 : 0} ครั้ง</Typography>
      </Stack>
      <Box
        gap={2}
        mt={2}
        display={"flex"}
        alignItems="center"
        justifyContent="center"
      >
        <LoadingButton
          size="large"
          style={{
            width: 100,
          }}
          loading={isLoading}
          variant="contained"
          onClick={async () => {
            await wheelInit();
            await spin("ticket");
          }}
        >
          หมุน
        </LoadingButton>
        <LoadingButton
          size="large"
          style={{
            width: 100,
            color: theme.palette.getContrastText(theme.palette.primary.main),
          }}
          color="warning"
          loading={isLoading}
          variant="contained"
          onClick={async () => {
            await wheelInit();
            await spin("credit");
          }}
        >
          ซื้อสปิน
        </LoadingButton>
      </Box>
    </Box>
  );
};
export default Spinner;
